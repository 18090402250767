import React, { useEffect, useState } from "react";
import DollarComponent from "../basics/dollar-component";

const EpochRewardsAllPools = () => {
  const [ rewards, setRewards ] = useState();

  useEffect(() => {
    if(!rewards) {
      fetch(process.env.REACT_APP_ORACLE_URL + "/pools/epoch-rewards-platform", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        }
      })
      .then(async(res) => (res) ? await res.json() : undefined)
      .then(data => {
        setRewards((typeof data ==='number') ? data : undefined)
      })
      .catch(_ => setRewards())
    }
  },
    [rewards]
  );

  if(typeof rewards === 'number'){
    return <DollarComponent amount={rewards}/>
  }

  return <>Loading…</>
};

export default EpochRewardsAllPools;
