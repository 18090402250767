import PoolsContainer from "../pools-container/pools-container";
import PortfolioBox from "../portfolio-box/portfolio-box";
import PlatformBox from "../platform-box/platform-box";
import { useAccount, useSigner } from "wagmi";
import {Helmet} from "react-helmet";

const RewardPools = () => {
  const { isConnected, address: userAddress } = useAccount(); 
  //IMPORTANT: useSigner && useAccount are needed two for not rerender never <AllPools/> and others
  const { status: signerStatus, data: signerData } = useSigner();
  if(signerStatus!=="loading" && (
    (!signerData && !userAddress) ||
    (signerData && signerData._address === userAddress)
   )
  ){
    return (
      <>
        <Helmet>
          <title>zkMakers | Decentralized Market-Making Platform</title>
        </Helmet>
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <PlatformBox />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <PortfolioBox userAddress={userAddress} isConnected={isConnected} />
          </div>
        </div>
  
        <div className="row bigbox">
          <div className="col-12">
            <PoolsContainer userAddress={userAddress} isConnected={isConnected} />
          </div>
        </div>
      </>
    );
  }
};

export default RewardPools;
