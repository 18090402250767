import { useEffect, useState } from "react";
import NumberComponent from "../basics/number-component";

const EpochTVCByPool = ({ poolAddress, currentEpoch, startDate }) => {
  const [epochTVCPoolId, setEpochTVCPoolId] = useState();

  useEffect(() => {
    const startTimestamp = parseInt(startDate) + ((parseInt(currentEpoch) - 1) * 604800);
    const endTimestamp = Math.round(Date.now()/1000);

    const url = process.env.REACT_APP_ORACLE_URL + 
    "/pools/epoch-tvc-id/" + 
      poolAddress + 
      "/" + 
      startTimestamp + 
      "/" + 
      endTimestamp;

    fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        }
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        if(data !== epochTVCPoolId){
          setEpochTVCPoolId(data);
        }
      })
      .catch((_) => {
        // nothing more to say
      })
  });

  if(epochTVCPoolId!== undefined){
    return (
      <>
        <span className="blue">$</span>
        <NumberComponent num={parseFloat(epochTVCPoolId)}/>
      </>
    )
  }

  return <>Loading…</>
};

export default EpochTVCByPool;
