import { useContractRead } from "wagmi"
import poolABI from "../../assets/abis/pool.json"
import NumberComponent from "../basics/number-component"

const EpochCertifVol = ({epoch, address}) => {
    const { data, isLoading} = useContractRead({
        address,
        abi: poolABI,
        functionName: "totalPoints",
        args: [epoch],
        chainId: process.env.REACT_APP_CHAIN_ID,
        enabled: epoch,
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SHORT,
    })

    if(isLoading || !data){
        return <>Loading…</>
    }
    return <>
        <span className="blue">$</span>
        <NumberComponent num={(data.toString()/10**18)}/>
    </>
}

export default EpochCertifVol
