import ChainLogo from "../chain-logo/chain-logo";
import exchangeList from "../../assets/json/exchanges-list.json";
import { NotificationManager } from 'react-notifications';
import "./exchange-name.scss";
import { memo } from "react";

const ExchangeName = ({ 
  addressTokenA, addressTokenB, chainId, exchangeName
 }) => {
  let exchangeNames = [];
  exchangeList.tags.map((el)=> exchangeNames.push(el.key));

  if (!exchangeNames.includes(exchangeName)) {
    NotificationManager.error("An error has occurred reading the smart contract", 'Error!', process.env.NOTIFICATION_TIME);
    return;
  }

  localStorage.setItem("exchangeName", exchangeName);
  
  if (!exchangeList.exchangeTypes.dex.includes(exchangeName)) {
    return (
      <div>
        <img
          className="cex-img"
          src={`/assets/img/exchanges/${exchangeName}.svg`}
          alt="logo exchange"
        />
        <span className="capitalize"> {exchangeName}</span>
      </div>
    );
  }

  let dexPrefix = "https://pancakeswap.finance/swap";

  let chainName = "mainnet";

  if (Number(chainId) === 137) {
    chainName = "polygon";
  } else if (Number(chainId) === 56) {
    chainName = "bsc";
  } else {
    chainName = chainId;
  }

  let url = dexPrefix + "?chain=" + chainName;

  if (addressTokenA && addressTokenB) {
    url =
      url +
      "&outputCurrency=" +
      addressTokenB +
      "&inputCurrency=" +
      addressTokenA;
  }

  if (exchangeName === "uniswap") {
    url = '/swap/uniswap/' + addressTokenA + '/' + addressTokenB + '/' + chainId;
  }

  return (
    <div>
      <a
        href={url}
        rel="noreferrer"
        target="_blank"
        title="Trade"
        className="exchange-link"
      >
        <img
          className="cex-img"
          src={`/assets/img/exchanges/${exchangeName}.svg`}
          alt="logo exchange"
        />
        <ChainLogo chainId={chainId}></ChainLogo>
        <span className="capitalize"> {exchangeName}</span>
      </a>
    </div>
  );
};

export default memo(ExchangeName);
