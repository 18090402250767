import { useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import RewardToken from "../reward-token/reward-token";
import { ethers } from "ethers";

const ClaimableRewards = ({ poolAddress, userAddress, currentEpoch, rewardToken }) => {
  const poolContract = {
    address: poolAddress,
    abi: PoolABI,
    functionName: "pendingReward",
    chainId: process.env.REACT_APP_CHAIN_ID,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
  }

  //can claim since last epoch (not current) and 4 before
  const contractQueries = [];
  for(let iWeek = 0; iWeek < 4; iWeek++){
  
    const epochWeek = currentEpoch - iWeek -1; 
    if(epochWeek <= 0){
      break;
    }
    contractQueries.push(
      {
        ...poolContract,
        args: [userAddress, epochWeek],
      }
    );
  }

  const {data, isLoading } = useContractReads({
    contracts: contractQueries,
    enabled: !!userAddress && contractQueries.length > 0,

  });
  if (isLoading) {
    return <>Loading…</>
  }

  let pendingRewards = ethers.BigNumber.from('0');
  if(userAddress && contractQueries.length > 0 && Array.isArray(data) && data.length > 0){
    data.map(
      el => pendingRewards = pendingRewards.add(ethers.BigNumber.from(el))
    )
  }
  
  return (
    <RewardToken
      amount={pendingRewards}
      address={rewardToken}
    />
  );
};

export default ClaimableRewards;
