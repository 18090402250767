import { memo } from "react";

const AllPoolsViewEmpty = () => {
  return (
    <div className="col-12 mt-5">
      <h5 className="warning-loading-pools text-center">
        <span>No pools match for your search criteria</span>
      </h5>
    </div>
  );
}

export default memo(AllPoolsViewEmpty);