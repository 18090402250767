import { useAccount, useContractReads } from 'wagmi';
import PoolABI from "../assets/abis/pool.json";

export function usePendingRewards(addressPool) {

  const { address, isConnecting } = useAccount();

    const lmPoolContract = {
        address: addressPool,
        abi: PoolABI,
        chainId: process.env.REACT_APP_CHAIN_ID,
        enabled: !!address && !isConnecting,
    };


    const { data } = useContractReads({
      contracts: [
        {
          ...lmPoolContract,
          functionName: "getCurrentEpoch",
        },{
          ...lmPoolContract,
          functionName: "rewardToken",
        },
      ],
      cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,

    });

    let claimReads = [];
  
    let currentEpoch = data ? data[0] : 1;
  
    for (
      let i = currentEpoch - 1;
      i >= (currentEpoch - 1 > 5 ? currentEpoch - 3 : 1);
      i--
    ) {
      claimReads.push({
        ...lmPoolContract,
        functionName: "pendingReward",
        args: [address, i],
        enabled: !!address
      });
    }
  
    const { data: pendingEpochsToClaim } = useContractReads({
      contracts: claimReads,
      enabled: !!address,
      cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
    });

    let totalAmount = 0;
    if (pendingEpochsToClaim && pendingEpochsToClaim[0] === null) {
        return {epochsToClaim: [], totalAmount: 0}
    }
  
    let epochsToClaim = pendingEpochsToClaim
      ?.map((value, i) => {
        if (value === 0) {
          return null;
        }
        totalAmount += Number((Number(value) / 10 ** 18).toFixed(2));
        return data[0] - 1 - i;
      })
      .filter((value) => value != null);
    
    return {epochsToClaim, totalAmount}
}
