import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "./chart.scss";
import PoolABI from "../../assets/abis/pool.json";
import { useContractReads } from "wagmi";
import { ethers } from "ethers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const Chart = ({ currentEpoch, poolAddress, rewardToken }) => {
  const decimals = parseInt(process.env.REACT_APP_REWARD_TOKEN_DECIMALS_DEFAULT);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});
  const [dataActive, setDataActive] = useState("1");
  let epochs = [];

  for (let i = 1; i <= parseInt(currentEpoch); i++) {
    epochs.push(i);
  }

  if (epochs.length > 7) {
    epochs = epochs.slice(epochs.length - 7);
  }

  let apyPoolContract = [];
  let poolContracts = [];
  epochs.map((epoch) => {
    apyPoolContract.push({
      address: poolAddress,
      abi: PoolABI,
      chainId: Number(process.env.REACT_APP_CHAIN_ID),
      functionName: "getRewardsPerEpoch",
      args: [epoch],
    });

    poolContracts.push({
      address: poolAddress,
      abi: PoolABI,
      chainId: Number(process.env.REACT_APP_CHAIN_ID),
      functionName: "totalPoints",
      args: [epoch],
    });

    return poolContracts;
  });

  let { data: totalPointsData, isLoading: totalPointsDataLoading } =
    useContractReads({
      contracts: poolContracts,
    });

  let { data: rewardsPerEpochData, isLoading: rewardsPerEpochDataLoading } =
    useContractReads({
      contracts: apyPoolContract,
    });

  const handleClickData = (event) => {
    setDataActive(event.target.id);
  };

  useEffect(() => {
    let chartLabel = [];
    epochs.map((item) => {
      return chartLabel.push("Epoch " + item);
    });
    chartLabel = chartLabel.slice(chartLabel.length - 7);

    if (chartLabel.length <= 6) {
      chartLabel.unshift(0);
    }

    let tvcData = [];
    let apyData = [];
    if (
      !totalPointsDataLoading &&
      totalPointsData.length > 0
    ) {
      tvcData = totalPointsData.map((item) => {
        return parseFloat(ethers.utils.formatEther(item).toString()).toFixed(decimals);
      });

      if (tvcData.length <= 6) {
        tvcData.unshift(0);
      }
    }

    if (!rewardsPerEpochDataLoading && rewardsPerEpochData.length > 0) {
      for (let i = 0; i < rewardsPerEpochData.length; i++) {
        if (
          rewardsPerEpochData[i].toString() === "0" ||
          totalPointsData[i].toString() === "0"
        ) {
          apyData.push("0");
        } else {
          apyData.push(
            (
              (parseInt(rewardsPerEpochData[i].toString()) /
                parseInt(totalPointsData[i].toString())) *
              100
            ).toFixed(2)
          );
        }
      }

      if (apyData.length <= 6) {
        apyData.unshift(0);
      }
    }

    setChartData({
      labels: chartLabel,
      datasets: [
        {
          data: dataActive === "1" ? tvcData : apyData,
          borderColor: "rgb(10, 231, 248)",
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 180);
            gradient.addColorStop(0, "rgba(10, 231, 248,1)");
            gradient.addColorStop(1, "rgba(10, 231, 248,0)");
            return gradient;
          },
          fill: true,
          lineTension: 0.4,
          radius: 1,
          borderWidth: 1,
        },
      ],
    });
    setChartOptions({
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    });
  }, [totalPointsData, totalPointsDataLoading, dataActive]);

  return (
    <>
      <div className="dark-box">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex align-items-end">
            {dataActive === "1" ? (
              <p className="chart-title">Historical rate</p>
            ) : (
              <p className="chart-title">Historical rate (in %)</p>
            )}
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4">
                <button
                  className={
                    dataActive === "1"
                      ? "chart-selector selected"
                      : "chart-selector"
                  }
                  id={"1"}
                  onClick={handleClickData}
                >
                  TVC
                </button>
              </div>
              <div className="col-4">
                <button
                  className={
                    dataActive === "2"
                      ? "chart-selector selected"
                      : "chart-selector"
                  }
                  id={"2"}
                  onClick={handleClickData}
                >
                  APY
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <Line options={chartOptions} data={chartData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
