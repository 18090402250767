import NumberComponent from "../basics/number-component";

const EpochTVC = ({ amount }) => {
  return (
    <>
      <span className="dollar">$</span>
      <NumberComponent num={amount}/>
    </>
  );
};

export default EpochTVC;
