import { useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import { gql, useQuery } from "@apollo/client";
import { ethers } from "ethers";
import AllClaimableRewardsView from "./all-claimable-rewards-view";
import ZeroUsd from "../basics/zero-usd";

const AllClaimableRewards = ({ address }) => {
  address = address.toLowerCase();
  const pointsLast4Weeks = gql`
    query getPointsMintedEntities(
      $user: String
      $timestamp1Week: Int
      $timestamp5Weeks: Int
    ) {
      pointsMintedEntities(
        where: {
          user: $user
          created_lte: $timestamp1Week
          created_gte: $timestamp5Weeks
        }
      ) {
        user
        pool
        epoch
      }
    }
  `;

  const timestamp1Week = parseInt(Date.now() / 1000);
  const timestamp5Weeks = parseInt(Date.now() / 1000 - (604800 * 4));

  let { data: last4Weeks, loading: last4WeeksLoading } = useQuery(
    pointsLast4Weeks,
    {
      variables: {
        user: address,
        timestamp1Week,
        timestamp5Weeks,
      },
    }
  );
  

  const lmContractTemplate = {
    abi: PoolABI,
    chainId: process.env.REACT_APP_CHAIN_ID,
  }
  const lmContracts = [];

  last4Weeks?.pointsMintedEntities.map((item) => {
    lmContracts.push(
      {
        ...lmContractTemplate,
        address: item.pool,
        functionName: "rewardToken",
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
      },
      {
        ...lmContractTemplate,
        address: item.pool,
        functionName: "pendingReward",
        args: [address, item.epoch],
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
      }
    );
  });

  const { data: dataPools, isLoading: isLoadingPools } = useContractReads({
    contracts: lmContracts,
    enabled: last4Weeks && !last4WeeksLoading && lmContracts.length > 0
  }); 

  if(!last4Weeks || last4WeeksLoading || isLoadingPools) {
    return <>Loading…</>
  }

  if(
    last4Weeks.pointsMintedEntities.length === 0 || 
    !Array.isArray(dataPools) ||
    dataPools.length === 0
  ){
    return <ZeroUsd/>
  }

  let rewardsRaw = {};
  let rewards = [];
  let rewardToken = '';
  let flagOddEven = true;
  // eslint-disable-next-line
  dataPools.map(el => {
    flagOddEven =! flagOddEven;
    if(!flagOddEven){
      rewardToken = el
    }else{
      if(!rewardsRaw[rewardToken]){
        rewardsRaw[rewardToken] = ethers.BigNumber.from('0');
      }
      rewardsRaw[rewardToken] = rewardsRaw[rewardToken].add(el);
    }
  });

  Object.keys(rewardsRaw).map((rewardToken) => {
    rewards.push({
        chainId: process.env.REACT_APP_CHAIN_ID,
        rewardToken,
        amount: rewardsRaw[rewardToken].toString(),
        startTimestamp: timestamp5Weeks,
        endTimestamp: timestamp1Week
    });
  })
  return <AllClaimableRewardsView rewards={rewards}/>
};

export default AllClaimableRewards;
