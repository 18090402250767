import { memo } from "react";

const NumberComponent = ({num, decimals}) => {

    if(typeof num === 'string'){
        num = parseFloat(num);
    }else if(typeof num === 'undefined'){
        return '';
    }
    
    num = num.toLocaleString('en-US');

    const tmpDecimals = num.split('.');
    if(tmpDecimals.length === 2){
        const decimalsStr = tmpDecimals[1].toString().substring(2,0);
        num = tmpDecimals[0] + "." + decimalsStr + ((decimalsStr.length === 1) ? '0':'');
      
    }else{
      num = num + "." + "0".repeat(decimals || process.env.REACT_APP_DEFAULT_NUM_DECIMALS)
    }

    
    return num;
}

export default memo(NumberComponent);

