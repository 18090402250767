import { useEffect, useState } from "react";
import DollarComponent from "../basics/dollar-component";

const AllClaimableRewardsView = ({ rewards }) => {
    const [ amountUsd, setAmountUsd ] = useState();
    useEffect(() => {
        if(typeof amountUsd === "undefined") {
            fetch(
                process.env.REACT_APP_ORACLE_URL + "/pools/convert-reward-tokens-to-usd",
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    method: 'POST',
                    body: JSON.stringify(rewards)
                }
            )
            .then(async res => {
                if(res){
                    return await res.json();
                }else{
                    return undefined;
                }
            })
            .then(data => {
                let amountTmp = 0;
                if(data.length > 0) {
                    // eslint-disable-next-line
                    data.map((reward) => {
                        amountTmp += reward.amountUsd;
                    });

                }
                setAmountUsd(amountTmp);
            })
            .catch((_) => {
              // nothing more to say
            });
        }
    },[
        amountUsd
    ]);
    if(typeof amountUsd !== "undefined"){
        return <DollarComponent amount={amountUsd.toString()}/>
    }
    return <>Loading…</>
};

export default AllClaimableRewardsView;
