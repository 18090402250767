import { useContractRead } from "wagmi"
import poolABI from "../../assets/abis/pool.json"
import NumberComponent from "../basics/number-component"

const WalletCertifVol = ({ wallet, epoch, address}) => {
    const { data, isLoading} = useContractRead({
        address,
        abi: poolABI,
        functionName: "userInfo",
        args: [wallet, epoch],
        chainId: process.env.REACT_APP_CHAIN_ID,
        enabled: wallet,
        cache: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT
    })
    
    if(isLoading){
        return <>Loading…</>
    }

    return <>
        <span className="blue">$</span>
        <NumberComponent num={(data.amount.toString()/10**18)}/>
    </>
}

export default WalletCertifVol
