import { memo } from "react";

const DataBoxLoader = ({message}) => {
  if(!message) {
    message = "Looking for your new trades on this epoch...";
  }
  return (
    <div className="box d-flex flex-column justify-content-center align-items-center variable-screen">
      <div className="row d-flex mt-3">
        <div className="col-12 w-100 text-center">
          <h5 className="white center">{message}</h5>
        </div>
      </div>
    </div>
  )
}

export default memo(DataBoxLoader);