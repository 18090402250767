import { useState } from "react";
import { useContractRead, useSignMessage } from "wagmi";
import { useParams } from "react-router-dom";
import PoolABI from "../../assets/abis/pool.json";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import "./api-key-box.scss";
import DataBoxLoader from "../loaders/data-box-loader";
import { NotificationManager } from 'react-notifications';
import exchangeList from "../../assets/json/exchanges-list.json";

const ApiKeyBox = ({ setScreen, setCertifiedData, senderAddress, isConnected }) => {
  const timestampMs = Date.now();
  const { poolAddress } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // States for certification
  let prevApiKey = "";
  let prevSecretKey = "";
  let prevPassword = "";
  const exchangeName = localStorage.getItem("exchangeName");
  let rawCredentials = localStorage.getItem(exchangeName + "Credentials");
  if (rawCredentials) {
    rawCredentials = JSON.parse(rawCredentials);
    prevApiKey = rawCredentials.apiKey;
    prevSecretKey = rawCredentials.secretKey;
    prevPassword = rawCredentials.password;
  }
  // const { address: senderAddress, isConnected } = useAccount();
  const [apiKey, setApiKey] = useState(prevApiKey);
  const [secretKey, setSecretKey] = useState(prevSecretKey);
  const [password, setPassword] = useState(prevPassword);

  let isDex = false;
  if(exchangeList.exchangeTypes.dex.includes(exchangeName.toLowerCase())) {
    isDex = true;
  }

  const { data: epoch } = useContractRead({
    address: poolAddress,
    abi: PoolABI,
    functionName: "getCurrentEpoch",
    chainId: process.env.REACT_APP_CHAIN_ID,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT
  });

  const { signMessage } = useSignMessage({
    message:
      "Sign this message to verify your wallet in zkMakers - " +
      timestampMs,
    onSettled(data, error) {
      if (error) {
          alert(error);
        return;
      }
      obtainProof({ signature: data, timestampMs });
    },
  });

  const exchangeNumArgs = (() => {
    if (exchangeName === "kucoin") {
      return 3;
    } else if (exchangeName === "gate" || exchangeName === "binance") {
      return 2;
    } else {
      return 0;
    }
  })();

  // Handling the name change
  const handleApiKey = (e) => setApiKey(e.target.value);
  const handleSecretKey = (e) => setSecretKey(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const authWallet = async () => signMessage();

  const obtainProof = async (params) => {
    setIsLoading(true);
    await fetch(
      process.env.REACT_APP_ORACLE_URL +
        "/signatures/create-signed-proof-" +
        (isDex ? "dex" : "cex"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          ...params,
          poolAddress,
          senderAddress,
          epoch: parseInt(epoch)
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setIsLoading(false);
        if (
          (response.statusCode >= 200 && response.statusCode < 300) ||
          !response.statusCode
        ) {
          setCertifiedData(response);
          setScreen("thirdScreen");
        }else {
          NotificationManager.error(response.message, 'Error!', process.env.NOTIFICATION_TIME);
        }
      })
      .catch(async (e) => {
        const response = await e.message;
        NotificationManager.error(response, 'Error!', process.env.NOTIFICATION_TIME);
        setIsLoading(false);
      });
  };

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      (exchangeNumArgs === 3 &&
        (apiKey === "" || secretKey === "" || password === "")) ||
      (exchangeNumArgs === 2 && (apiKey === "" || secretKey === ""))
    ) {
      NotificationManager.error("Please enter all the fields", 'Error!', process.env.NOTIFICATION_TIME);
    } else {
      localStorage.setItem(
        exchangeName + "Credentials",
        JSON.stringify({ apiKey, secretKey, password })
      );
      obtainProof({
        exchangeApiKey: apiKey,
        exchangeSecret: secretKey,
        exchangePassword: exchangeNumArgs === 3 ? password : "",
      });
    }
  };

  if(!epoch || isLoading) {
    return <DataBoxLoader/>
  }

  if (isConnected) {
    return (
      <>
        <div className="box variable-screen">
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-12 w-90 text-center">
              <p>
              {isDex 
              ? "Please click authenticate wallet before proving your records"
              : "Please enter the host exchange API KEYs (how to find your API Keys on the Exchange)"
              }</p>
            </div>
          </div>
          {isDex 
          ? (
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-center">
                <button onClick={authWallet} className="blue-dark-button">
                  Authenticate wallet first
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="row mt-3 justify-center">
                <form>
                  <div className="col-10 offset-1">
                    <label>API Key</label>
                    <input
                      className="black"
                      onChange={handleApiKey}
                      type="password"
                      value={apiKey}
                    />
                  </div>
                  <div className="col-10 offset-1 private-key-btn mt-3">
                    <label>Secret</label>
                    <input
                      className="black"
                      onChange={handleSecretKey}
                      type="password"
                      value={secretKey}
                    />
                  </div>
                  {exchangeNumArgs === 3 ? (
                    <div className="col-10 offset-1 private-key-btn mt-3">
                      <label>Passphrase</label>
                      <input
                        className="black"
                        onChange={handlePassword}
                        type="password"
                        value={password}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
              <div className="row d-flex justify-content-center mt-3">
                <div className="col-10 text-center">
                  <p>
                    Remember, you only need <b>read only</b> permissions for your
                    API keys. Learn more{" "}
                    <a className="link" href="https://www.binance.com/en/support/faq/how-to-create-api-360002502072">
                      here
                    </a>
                  </p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-center">
                  <button onClick={handleSubmit} className="blue-dark-button">
                    Prove your records
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="row d-flex justify-content-center mt-4">
            <div className="col-10 text-center">
              <p>
                <img src="/assets/img/warning-icon.png" alt="warning icon" />{" "}
                Remember that you will need to certify your orders within the
                next 7 days after your last certification or your first trade
              </p>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="box variable-screen">
        <ConnectYourWalletFirst/>
      </div>
    );
  }
};

export default ApiKeyBox;
