import { erc20ABI, useContractReads } from "wagmi";
import { memo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { initRewardTokens } from "../../features/reward-tokens-slice";
import { save } from "../../features/pinned-pools-slice";
import pinnedPools from "../../assets/json/pinned-pools-list";

const queryPinnedPoolsRaw = `query getPoolEntities {
  poolEntities(
    CONDITION_IDS
  ) {
    id
    pairTokenA
    pairTokenB
    chainId
    endRewardsDate
    exchange
  }
}
`;

const Init = () => {
  const [ queryFinished, setQueryFinished ] = useState(false);
  const [ rewardTokens, setRewardTokens ] = useState([]);
  const query = gql`query getRewardTokensAccepted {
    rewardTokenStatusEntities(where: {accepted: true}) {
      id
    }
  }`;
  const CONDITION_IDS = "where: { id_in: [\"" + Object.keys(pinnedPools).join("\", \"") + "\"]}";
  const queryPinnedPools = queryPinnedPoolsRaw.replace('CONDITION_IDS', CONDITION_IDS);

  const pinnedPoolsSaved = useSelector(state => state.pinnedPools);
  const dispatch = useDispatch();

  useQuery(query, {
    skip: queryFinished,
    onCompleted(data) {
      setQueryFinished(true);
      let tmpTokens = [];
      // eslint-disable-next-line
      data.rewardTokenStatusEntities.map(el => {
        tmpTokens.push(el.id);
      });
      setRewardTokens(tmpTokens);
    }
  });

  useQuery(gql`${queryPinnedPools}`, {
    skip: Object.keys(pinnedPoolsSaved).length > 0,
    onCompleted(data) {
      if(data && Array.isArray(data.poolEntities)) {
        let pinnedPoolsData = {};
        data.poolEntities.map(pool => {
          pinnedPoolsData[pool.id] = {
            id: pool.id,
            pairTokenA: pool.pairTokenA,
            pairTokenB: pool.pairTokenB,
            chainId: pool.chainId,
            endRewardsDate: pool.endRewardsDate,
            exchange: pool.exchange
          }
        });
        dispatch(save(pinnedPoolsData));
      } 
    }
  })

  const rewardTokenContractTemplate = {
    abi: erc20ABI,
    chainId: process.env.REACT_APP_CHAIN_ID,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
  }

  let contracts = [];
  if(rewardTokens.length > 0) {
    // eslint-disable-next-line
    rewardTokens.map((address) => {
      contracts.push(
        {
          ...rewardTokenContractTemplate,
          address,
          functionName: "decimals"
        },
        {
          ...rewardTokenContractTemplate,
          address,
          functionName: "symbol"
        }
      );
    });
  }

  useContractReads({
    contracts,
    enabled: queryFinished && contracts.length > 0,
    onSuccess(data){
      let rewardTokensRaw = {};
      if(data.length > 0){
        let flagOddEven = true;

        // eslint-disable-next-line
        data.map((el, index) => {
          flagOddEven =! flagOddEven;
          if(!rewardTokensRaw[contracts[index].address.toLowerCase()]) {
            rewardTokensRaw[contracts[index].address.toLowerCase()] = {
              decimals: 0,
              symbol: '',
              address: ethers.utils.getAddress(contracts[index].address)
            }
          }
          if(!flagOddEven) {
            rewardTokensRaw[contracts[index].address.toLowerCase()].decimals = el;
          }else {
            rewardTokensRaw[contracts[index].address.toLowerCase()].symbol = el;
          }
        });
        dispatch(initRewardTokens(rewardTokensRaw));
      }
    },
  });
  
}

export default memo(Init);