import "./select.scss";

const Select = ({ title, items, onChange, defaultValue, defaultChecked, isConnected }) => {
  const getRadioCheckedValue = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="select-box">
      <div className="select-box__current" tabIndex="1">
        <div className="select-box__value">
          <input
            className="select-box__input"
            type="radio"
            id="default"
            value={defaultValue ? defaultValue : "default"}
            defaultChecked={!defaultChecked}
            name={title}
          />
          <p className="select-box__input-text">{title}</p>
        </div>
        {items.map((item, i) => {
          return (
            <div key={item.key + i} className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id={title + "option" + i}
                value={item.key}
                defaultChecked={defaultChecked === item.key}
                name={title}
                onChange={getRadioCheckedValue}
              />
              <p className="select-box__input-text">{item.image ? <img src={item.image} width="20px" alt="coin" />: <></>}{" "}{item.title}</p>
            </div>
          );
        })}
        <img
          className="select-box__icon"
          src="/assets/img/select-arrow.png"
          alt="Arrow Icon"
          aria-hidden="true"
        />
      </div>
      <ul className="select-box__list">
        {items.map((item, i) => {
          return (
            <li key={item.key + i}>
              <label
                className="select-box__option"
                htmlFor={title + "option" + i}
                aria-hidden="true"
              >
                {item.image ? <img src={item.image} width="20px" alt="coin" />: <></>}{" "}
                {item.title}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Select;
