import NumberComponent from "./number-component";

const DollarComponent = ({amount}) => {
    return (
        <>
            <span className="dollar">$</span>
            <NumberComponent num={amount}/>
        </>
    )
}
export default DollarComponent;