import React, { memo, useState } from "react";
import AllPools from "../all-pools/all-pools";
import Select from "../selects/select";
import "./pools-container.scss";
import TokenSelect from "../selects/token-select";
import { constants } from "ethers";
import filters from "../../assets/json/filters.json";
import { useSelector, useDispatch } from "react-redux";
import { addLastSearch } from "../../features/search-slice";

const { exchangeFiltersList, sortingFiltersList, stateFiltersListConst } =
  filters;

const PoolsContainer = ({ userAddress, isConnected }) => {
  const dispatch = useDispatch();
  let stateFiltersList = stateFiltersListConst;
  let lastSearch = useSelector((state) => state.search.last);
  let lastState = lastSearch.state;

  if (!isConnected) {
    stateFiltersList = stateFiltersList.filter(
      (el) => el.key !== "participatedInPools" && el.key !== "poolsCreatedByMe"
    );
    if (
      lastState.key === "participatedInPools" ||
      lastState.key === "poolsCreatedByMe"
    ) {
      lastState = stateFiltersList[1];
    }
  }
  lastSearch = useSelector((state) => state.search.last);

  const [tokenAddressesA, setTokenAddressesA] = useState(
    lastSearch.tokenAddressesA
  );
  const [tokenAddressesB, setTokenAddressesB] = useState(
    lastSearch.tokenAddressesB
  );
  const [lastOptionSelectedA, setLastOptionSelectedA] = useState(
    lastSearch.lastOptionSelectedA
  );
  const [lastOptionSelectedB, setLastOptionSelectedB] = useState(
    lastSearch.lastOptionSelectedB
  );

  const [exchange, setExchange] = useState(lastSearch.exchange.key);

  const [state, setState] = useState(lastState.key);
  const [sorting, setSorting] = useState(lastSearch.sorting.key);
  const [allPoolsRenderKey, setAllPoolsRenderKey] = useState(Math.random);

  const setNewLastSearch = ({
    tokenAddressesA,
    lastOptionSelectedA,
    tokenAddressesB,
    lastOptionSelectedB,
    exchange,
    state,
    sorting,
  }) => {
    let newLastSearch = {};
    newLastSearch.tokenAddressesA = tokenAddressesA;
    newLastSearch.tokenAddressesB = tokenAddressesB;
    newLastSearch.lastOptionSelectedA = lastOptionSelectedA;
    newLastSearch.lastOptionSelectedB = lastOptionSelectedB;

    exchangeFiltersList.map((el, index) => {
      if (el.key === exchange) {
        newLastSearch.exchange = exchangeFiltersList[index];
      }
    });

    stateFiltersList.map((el, index) => {
      if (el.key === state) {
        newLastSearch.state = stateFiltersList[index];
      }
    });

    sortingFiltersList.map((el, index) => {
      if (el.key === sorting) {
        newLastSearch.sorting = sortingFiltersList[index];
      }
    });

    dispatch(addLastSearch(newLastSearch));
  };

  const handleTokenAddresses = (
    tokenAddresses,
    lastOptionSelected,
    selectId
  ) => {
    let tokenAddressesArray = null;

    if (tokenAddresses !== null) {
      tokenAddressesArray = Object.keys(tokenAddresses).map(
        (key) => tokenAddresses[key]
      );
      if (
        tokenAddressesArray[0] === "" ||
        (!tokenAddresses["ethereum"] &&
          !tokenAddresses["polygon-pos"] &&
          !tokenAddresses["binance-smart-chain"])
      ) {
        tokenAddressesArray = [constants.AddressZero];
      }
    }

    if (selectId === "A") {
      setTokenAddressesA(tokenAddressesArray);
      setAllPoolsRenderKey(Math.random());
      setLastOptionSelectedA(lastOptionSelected);
      setNewLastSearch({
        tokenAddressesA: tokenAddressesArray,
        lastOptionSelectedA: lastOptionSelected,
        tokenAddressesB,
        lastOptionSelectedB,
        exchange,
        state,
        sorting,
      });
    } else if (selectId === "B") {
      setTokenAddressesB(tokenAddressesArray);
      setAllPoolsRenderKey(Math.random());
      setLastOptionSelectedB(lastOptionSelected);
      setNewLastSearch({
        tokenAddressesA,
        lastOptionSelectedA,
        tokenAddressesB: tokenAddressesArray,
        lastOptionSelectedB: lastOptionSelected,
        exchange,
        state,
        sorting,
      });
    }
  };

  const handleExchangeFilterChange = (event) => {
    setExchange(event);
    setAllPoolsRenderKey(Math.random());
    setNewLastSearch({
      tokenAddressesA,
      tokenAddressesB,
      lastOptionSelectedA,
      lastOptionSelectedB,
      exchange: event,
      state,
      sorting,
    });
  };
  const handleStateFilterChange = (event) => {
    setState(event);
    setAllPoolsRenderKey(Math.random());
    setNewLastSearch({
      tokenAddressesA,
      tokenAddressesB,
      lastOptionSelectedA,
      lastOptionSelectedB,
      exchange,
      state: event,
      sorting,
    });
  };
  const handleSortingFilterChange = (event) => {
    setSorting(event);
    setAllPoolsRenderKey(Math.random());
    setNewLastSearch({
      tokenAddressesA,
      tokenAddressesB,
      lastOptionSelectedA,
      lastOptionSelectedB,
      exchange,
      state,
      sorting: event,
    });
  };

  return (
    <div className="box">
      <div className="row justify-center">
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 pt-20">
          <TokenSelect
            handleTokenAddresses={(tokenAddresses, lastOptionSelected) =>
              handleTokenAddresses(tokenAddresses, lastOptionSelected, "A")
            }
            placeholder={"Type Token A…"}
            defaultValue={lastOptionSelectedA}
          />
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 pt-20">
          <TokenSelect
            handleTokenAddresses={(tokenAddresses, lastOptionSelected) =>
              handleTokenAddresses(tokenAddresses, lastOptionSelected, "B")
            }
            placeholder={"Type Token B…"}
            defaultValue={lastOptionSelectedB}
          />
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 pt-20">
          <Select
            onChange={handleExchangeFilterChange}
            title={lastSearch.exchange.title}
            items={exchangeFiltersList}
            defaultChecked={lastSearch.exchange.key}
          />
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 pt-20">
          <Select
            onChange={handleStateFilterChange}
            title={lastState.title}
            items={stateFiltersList}
            defaultChecked={lastState.key}
          />
        </div>

        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 pt-20">
          <Select
            onChange={handleSortingFilterChange}
            title={lastSearch.sorting.title}
            items={sortingFiltersList}
            defaultChecked={lastSearch.sorting.key}
          />
        </div>
      </div>

      <div className="row mt-4">
        <AllPools
          key={allPoolsRenderKey}
          tokenAddressesA={tokenAddressesA}
          tokenAddressesB={tokenAddressesB}
          exchange={exchange}
          state={state}
          sorting={sorting}
          timestamp={parseInt(Date.now() / 1000)}
          userAddress={userAddress}
        />
      </div>
    </div>
  );
};

export default memo(PoolsContainer);
