import "../token-symbol/token-symbol.scss";
import { memo } from "react";

const RewardTokenSymbol = ({ 
  address, 
  symbol,
  noLink 
}) => {
  const blockExplorerUrls = JSON.parse(process.env.REACT_APP_BLOCK_EXPLORER_URLS);
  return (
    <span className="blue">
      {" "}
      {!noLink 
      ? (
        <a 
          href={blockExplorerUrls.chainId[parseInt(process.env.REACT_APP_CHAIN_ID)] + address}
          target="_blank"
          className="token-symbol-link"
          rel="noreferrer noopener"
        >{symbol}</a>
      ):(
        <>{symbol}</>
      )}
    </span>
  )
};

export default memo(RewardTokenSymbol);
