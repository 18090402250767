import "./wave-button.scss";

const WaveButton = ({ text, customClass, onClick }) => {
  return (
    <a className={customClass} onClick={onClick}>
      <span>{text}</span>
      <div className="liquid"></div>
    </a>
  );
};

export default WaveButton;
