import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { infuraProvider } from "wagmi/providers/infura";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import CreateRewardPool from "./components/create-reward-pool/create-reward-pool";
import Faucet from "./components/faucet/faucet";
import Navbar from "./components/navbar/navbar";
import Pool from "./components/pool-detail/pool-detail";
import RewardPools from "./components/reward-pools/reward-pools";
import UniswapSwap from "./components/uniswap-swap/uniswap-swap";
import "./index.scss";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Page404 from "./components/special-pages/page-404";
import Init from "./components/init/init";
import { store } from './app/store'
import { Provider } from 'react-redux'

const apolloCache = new InMemoryCache();
const graphqlClient = new ApolloClient({
  cache: apolloCache,
  uri: process.env.REACT_APP_SUBGRAPH_URI,
});

const lmChain = {
  id: Number(process.env.REACT_APP_CHAIN_ID),
  name: process.env.REACT_APP_CHAIN_NAME,
  network: process.env.REACT_APP_CHAIN_NETWORK,
  nativeCurrency: {
    name: process.env.REACT_APP_CHAIN_SYMBOL,
    symbol: process.env.REACT_APP_CHAIN_SYMBOL,
    decimals: process.env.REACT_APP_CHAIN_DECIMALS,
  },
  rpcUrls: {
    default: process.env.REACT_APP_CHAIN_RPC,
  },
  testnet: process.env.REACT_APP_CHAIN_TESTNET,
  multicall: {
    address: process.env.REACT_APP_MULTICALL_ADDRESS,
    blockCreated: process.env.REACT_APP_MULTICALL_BLOCK_CREATED,
  },
};

const bscChain = {
  id: 56,
  name: "Binance Smart Chain",
  network: "BSC",
  nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
  rpcUrls: {
    default: "https://bsc-dataseed.binance.org/",
  },
  testnet: false,
};

const { chains, provider } = configureChains(
  [lmChain, bscChain, chain.mainnet, chain.polygon],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id !== lmChain.id && chain.id !== bscChain.id) return null;
        return { http: chain.rpcUrls.default };
      },
    }),
    infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY }),
  ]
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
  ],
  provider,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className="container-fluid">
    <>
    <Provider store={store}>
      <ApolloProvider client={graphqlClient}>
        <WagmiConfig client={client}>
          <BrowserRouter>
            <Init />
            <Navbar />
            <Routes>
              <Route exact path="/" element={<RewardPools />} />
              <Route exact path="/faucet" element={<Faucet />} />
              <Route exact path="/createPool" element={<CreateRewardPool />} />
              {/* <Route exact path="/pools" element={<RewardPools />} /> */}
              <Route exact path="/pool/:poolAddress" element={<Pool />} />
              <Route exact path="/swap/uniswap" element={<UniswapSwap />} />
              <Route
                exact
                path="/swap/uniswap/:chainId"
                element={<UniswapSwap />}
              />
              <Route
                exact
                path="/swap/uniswap/:tokenA/:tokenB/:chainId"
                element={<UniswapSwap />}
              />
              <Route
                path="*"
                element={<Page404 />}
              />
            </Routes>
            <NotificationContainer />
          </BrowserRouter>
        </WagmiConfig>
      </ApolloProvider>
    </Provider>
    </>
  </div>
);