import RewardTokenSymbol from "./reward-token-symbol";
import NumberComponent from "../basics/number-component";
import { memo } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";

const RewardToken = ({ 
    address, 
    noLink,
    amount
 }) => {
    let rewardTokenAddress = ((address) ? address : process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT).toLowerCase();
    let rewardTokenData = useSelector(state => state.rewardTokens.all[rewardTokenAddress]);
    if(!rewardTokenData) {
        rewardTokenData = {
            decimals: parseInt(process.env.REACT_APP_REWARD_TOKEN_DECIMALS_DEFAULT),
            symbol: process.env.REACT_APP_REWARD_TOKEN_SYMBOL_DEFAULT,
            address: process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT
        }
    }
    return (
        <>
            <NumberComponent 
                num={ethers.utils.formatEther(amount)}
                decimals={process.env.REACT_APP_REWARD_TOKEN_DECIMALS_DEFAULT}

            />
            {" "}
            <RewardTokenSymbol
                symbol={rewardTokenData.symbol}
                address={rewardTokenData.address} 
                noLink={noLink}
            />
        </>
    )
 }

export default memo(RewardToken);
