import { useSelector } from "react-redux";
import PoolBox from "../pool-box/pool-box";

const AllPinnedPools = ({userAddress}) => {
    const { all: allPools } = useSelector(state => state.pinnedPools);
    if(!allPools){ 
        return;
    }
    return (
        <>
          {(
            Object.values(allPools) &&
            Object.values(allPools).map(pool => {
                return (pool.id && (
                    <div className="col-12 mt-3" key={pool.id}>
                        <PoolBox
                            addressPool={pool.id}
                            addressTokenA={pool.pairTokenA}
                            addressTokenB={pool.pairTokenB}
                            exchangeName={pool.exchange}
                            chainId={parseInt(pool.chainId)}
                            endRewardsDate={pool.endRewardsDate}
                            userAddress={userAddress}
                            isPinnedPool={true}
                        />
                    </div>
                ))
            }))}
        </>
    )
} 

export default AllPinnedPools;