import { memo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import PoolBox from "../pool-box/pool-box";
import AllPoolsViewLoader from "../loaders/all-pools-view-loader";
import { constants } from "ethers";

const queryRaw = `query getPoolEntities($sorting: String) {
    poolEntities(
      orderBy: created
      orderDirection: $sorting
      CONDITION_IDS
    ) {
      id
      pairTokenA
      pairTokenB
      chainId
      endRewardsDate
      exchange
    }
  }
`;

const AllPoolsView = ({
  sorting,
  numResultsPerPage,
  numPages,
  numResults,
  dbPoolsIds,
  userAddress,
}) => {

  const [ page, setPage ] = useState(0);
  const [ first, setFirst ] = useState((sorting === 'asc') ? 0 : numResults);

  const handlePageChange = (newPageNum) => {
    if(sorting === 'asc') {
      setFirst(numResultsPerPage * newPageNum);
    }else {
      setFirst(numResults - (newPageNum * numResultsPerPage))
    }
    setPage(newPageNum);
  }

  const extractPoolIdsFromArrayDB = () => {
    let arrResponse = [];
    let step = 1;
    if(sorting === 'desc') {
      step = -1;
    }  
    for(let i = 0; i < numResultsPerPage; i++) {
      let iRow = first + (i * step);
      if(sorting === 'desc'){
        iRow--;
      }
      if(dbPoolsIds[iRow]) {
        arrResponse.push(dbPoolsIds[iRow]);
      }
    }
    return arrResponse;
  }

  const poolIds = extractPoolIdsFromArrayDB();
  const CONDITION_IDS = "where: { id_in: [\"" + 
    (
      (poolIds.length > 0) 
      ? poolIds 
      : [constants.AddressZero]
    ).join("\", \"") +
    "\"]}";

  const query = queryRaw.replace("CONDITION_IDS", CONDITION_IDS);
  
  const {
    data: pools,
    loading: loadingPoolsRaw,
    error: errorPoolsRaw,
    observable,

  } = useQuery(gql`${query}`, {
    variables: {
      sorting
    }
  });

  if(pools && !observable.isTornDown && !loadingPoolsRaw && !errorPoolsRaw) {
    const allPools = {};

    pools.poolEntities.map((pool) => allPools[pool.id] = pool);
    return (
      <>
        {(
          Object.values(allPools) &&
          Object.values(allPools).map((pool) => {
            return (pool.id && (
              <div className="col-12 mt-3" key={pool.id}>
                <PoolBox
                  addressPool={pool.id}
                  addressTokenA={pool.pairTokenA}
                  addressTokenB={pool.pairTokenB}
                  exchangeName={pool.exchange}
                  chainId={parseInt(pool.chainId)}
                  endRewardsDate={pool.endRewardsDate}
                  userAddress={userAddress}
                />
              </div>
            ))
          }))}
          <div className="row justify-center">
            {page >= 1 && (
              <>
                <div className="col-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(0)}
                    className="loading-pools click text-center"
                  >
                    <span>{"<<"}</span>
                  </h5>
                </div>

                <div className="col-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(page - 1)}
                    className="loading-pools click text-center"
                  >
                    <span>{"<"}</span>
                  </h5>
                </div>
              </>
            )}

            <div className="col-1 mt-5">
              <h5 className="loading-pools click text-center">
                <span>{page + 1}</span>
              </h5>
            </div>

            {
            (
              numResults > numResultsPerPage && 
              numPages > page
            ) && (
              <>
                <div className="col-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(page + 1)}
                    className="loading-pools click text-center"
                  >
                    <span>{">"}</span>
                  </h5>
                </div>

                <div className="col-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(numPages)}
                    className="loading-pools click text-center"
                  >
                    <span>{">>"}</span>
                  </h5>
                </div>
              </>
            )}
          </div>
          <div className="row justify-center text-center pt-20">
            <div className="col-lg-12">
              <span>
                We found {numResults ?? 0} pools
                {numResults > numResultsPerPage && (
                  <>
                    {" "}on {(
                      numPages % numResultsPerPage === 0 
                      ? numPages 
                      : numPages + 1
                    )} pages
                  </>
                )}
              </span>
            </div>
          </div>
      </>
    );
  }else{
    return <AllPoolsViewLoader/>
  }
}

export default memo(AllPoolsView);
