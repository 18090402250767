import {
  useNetwork,
  useSwitchNetwork,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import Button from "../buttons/button";
import { usePendingRewards } from "../../services/use-pending-rewards";
import WaveButton from "../buttons/wave-button";
import "./claim-button.scss";

const ClaimButton = ({ 
  text, 
  customClass, 
  addressPool, 
  rewardToken,  
  currentEpoch,
  isConnected
}) => {
  const { epochsToClaim } = usePendingRewards({addressPool, currentEpoch, rewardToken});
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { config: claimConfig } = usePrepareContractWrite({
    address: addressPool,
    abi: PoolABI,
    functionName: "multiClaim",
    args: [epochsToClaim],
    enabled: isConnected && Array.isArray(epochsToClaim) && epochsToClaim.length > 0
  });

  const { write: claimWrite, isLoading: isLoadingClaim } = useContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    ...claimConfig,
    enabled: Array.isArray(epochsToClaim) && epochsToClaim.length > 0
  });

  if (!epochsToClaim || (Array.isArray(epochsToClaim) && epochsToClaim.length === 0)) {
    return; // ToDo Show that there is nothing to claim
  }

  if (chain.id.toString() !== process.env.REACT_APP_CHAIN_ID) {
    return (
      <div className="col mt-2 center">
        <Button
          text="Change Network"
          type="button-light"
          onClick={() =>
            switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
          }
        />
      </div>
    );
  }
  if(!isLoadingClaim) {
    return (
      <div className="col end">
        <WaveButton
          id="button-claim"
          customClass={customClass}
          onClick={() => claimWrite?.()}
          text={text}
        />
      </div>
    );
  }
};

export default ClaimButton;
