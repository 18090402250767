import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { refresh } from "../../features/refresh-slice";

const allPoolsQuery = gql`
  query getPoolEntities($limit: Int, $offset: Int) {
    poolEntities(first: $limit, skip: $offset) {
      id
    }
  }
`;

const getPools = async (client, refreshValue) => {
  const limit = 1000;
  let total = 0;
  let numLastReaded = 0;
  let i = 0;
  do{
    const result = await client.query({
      query: allPoolsQuery,
      variables: {
        limit: limit,
        offset: limit * i,
      },
      fetchPolicy: refreshValue ? 'network-only' : 'cache-first'
    });

    numLastReaded = result.data.poolEntities.length;
    
    if(!result || numLastReaded === 0) {
      break;
    }
    total += numLastReaded;
    i++;
  }while(numLastReaded === limit);
  return total;
};

const usePoolCount = () => {
  
  const [poolsCounter, setPoolCounter] = useState(null);
  const refreshValue = useSelector(state => state.refresh.poolCounter);
  const dispatch = useDispatch();

  const client = useApolloClient();
  if(poolsCounter === null) {
    getPools(client, refreshValue)
    .then((data) => {
      setPoolCounter(data);
    })
    .catch((_) => {
      setPoolCounter(0);

    })
    .finally(()=>{
      dispatch(refresh({name: 'poolCounter', value: false}));
    });
  }
    
  return { data: poolsCounter };
};

const PoolCounter = () => {
  const { data } = usePoolCount();
  if (data === undefined || data === null) {
    return <>Loading...</>;
  }
  return data;
};

export default PoolCounter;
