import { useContractRead } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import RewardToken from "../reward-token/reward-token";
import { useSelector, useDispatch } from "react-redux";
import { refresh } from "../../features/refresh-slice";

const EpochRewards = ({ epoch, rewardToken, address, chainId }) => {
  const keyRendered = useSelector(state => state.refresh.epochRewards);
  const dispatch = useDispatch();

  if(keyRendered) {
    dispatch(refresh({name: 'epochRewards', value: false}))
  }

  const { data: rewards, isLoading } = useContractRead({
    address,
    abi: PoolABI,
    functionName: "getRewardsPerEpoch",
    chainId,
    args: [epoch],
    cacheOnBlock: keyRendered,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT
  });

  if (isLoading) {
    return <>Loading…</>
  }
  return (
    <RewardToken 
    amount={rewards}
    address={rewardToken}
  />
  );
};

export default EpochRewards;
